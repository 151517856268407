
.title-info-conatiner{
    width: 87%;
    height: 130px;
    background-color: khaki;
    display: flex;
    align-self: center;
    justify-content: space-around;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E4E8EC;
    border-radius: 4px;
    flex-wrap: wrap;
}

.left-side{
    flex-basis: 45%;
    margin-top: 8px;
}

.left-side div:nth-child(2){
    margin-top: 30px;
}

.left-side .category{
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #818E94;
    letter-spacing: 0.15px;
    line-height: 4px;
    text-indent: 4px;
}

.left-side .user-name{
    color: #4D4D4D;
    font-size: 20px;
    font-family: Roboto-Medium;
    line-height: 2px;
}


.right-side{
    flex-basis: 45%;
}

.right-side .total{
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #818E94;
    letter-spacing: 0.15px;
    line-height: 4px;
    text-indent: 4px;
}


.right-side .amount {
    color: #05CE78;
    font-size: 24px;
    font-family: Roboto-Medium;
    line-height: 2px;
    text-indent: 2px;
}

.right-side  a {
    color: #818E94;
    font-size: 18px;
    font-family: Roboto-Medium;
    text-transform: uppercase;
    padding-left: 7px;
    padding-bottom: 10px;
 
}
.right-side  .tax-deductible {
    color: #818E94;
    font-size: 14px;
    font-family: Roboto-Regular;
    position: relative;
    display: flex;
}


.img-card{
    width: 98%;
    display: flex;
    margin-top: -70px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
}

.img-card img {
    width: 38px;
    height: 24px;
    border: .5px solid #BFCED7;
    padding: 1px;
    margin-right: 5px;
    
    
}

.submit_btn button{
    width: 100% !important;
    margin: 10px 0px;
    letter-spacing: 1.26px;
    height: 48px;
    margin-bottom: 20px;

}

.submit_btn button span{
    letter-spacing: 1.26px;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto-Regular;

}

.verify button{
    width: 97%;
    margin: 10px 0px;
    margin-left:5px;
}

.MuiGrid-container .MuiTextField-root{
    margin: 4px 0;
}

.grecaptcha-badge{
    z-index: 999;
}

.border_submit_btn {
    background: #E0E0E0;
    height: 1px;
    margin-left: -50px;
    margin-right: -50px;
    margin-bottom: 30px;
    margin-top: 20px;
}
.tel_error_msg{
    margin-top: 0px !important;
}

.field_card{
    width: 40px;
    border-radius: 3px;
}

.MuiTooltip-popper{
    width: 216px !important;
    height: 52px !important;
    margin-left: -111px !important;
    margin-top: -50px !important;
    margin-bottom: 9px !important;
    text-align: center !important;
    font-size: 12px !important;
    font-family: Roboto-Regular !important;

}



@media (max-width:990px){
.verify button{
    width: 100%;
    margin: 10px 0px;
    margin-left:0px;
}

.border_submit_btn {
    background: #E0E0E0;
    height: 1px;
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.tel_error_msg{
    margin-top: 0px !important;
}

}

@media (max-width:768px){
    .submit_btn button {
        width: 100% !important;
        margin: 15px 0px;
    }
    .MuiTooltip-popper{
        width: 220px !important;
        height: 40px !important;
        font-size: 2px !important;
        font-family: Roboto-Regular !important;
        margin-top: -93px !important;
    }
}

@media (max-width:768px){
    .submit_btn button {
        width: 100% !important;
        margin: 15px 0px;
    }
    .MuiTooltip-tooltip{
        display: flex;
        height: 24px;
        font-size: 10px  !important;
        font-family: Roboto-Regular !important;
        margin-top: -2px !important;
    }
    
}

.MuiCircularProgress-colorPrimary {
    color: rgb(5, 206, 120) !important;
}


@media (max-width:340px){
    .border_submit_btn {
        margin-left: -15px;
        margin-right: -15px;

    }
    .MuiTooltip-tooltip{
        display: flex;
        height: 24px;
        font-size: 10px  !important;
        font-family: Roboto-Regular !important;
        margin-top: -2px !important;
    }

}






