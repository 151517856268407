.donate_more_btn button{
    width: 100% !important;
}

.success_border_line {
    background: #eaeaea;
    height: 1px;
    margin: -50px;
    margin-right: -50px;
    margin-bottom: 30px;
    margin-top: 40px;
}


.Donation-Successful{
    color: var(--text-color) !important;
}

.jazak_Allah{
    text-align: center;
    margin-top: 40px;
}

.don_number{
    text-align: center;
    margin-top: 40px;
}

.btn_donate button {
    margin-bottom: 5px;
    margin-top: 0;
}
.succcess_page{
    width: 640px;
}

.succcess_page p{
    margin-bottom:8px;
}

/* .don_number{
    color: #7266FB;
} */

.don_number_ref{
    color: var(--text-color) !important;;  
}

span

.don_number span{
    color: #747474 !important;
}

@media (max-width:960px){
    .success_border_line {
    background: #eaeaea;
    height: 1px;
    margin: -25px;
    margin-right: -25px;
    margin-bottom: 30px;
    margin-top: 40px;

    }

    .succcess_page{
        width: 100%;
    }

}