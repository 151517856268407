
.react-tel-input .form-control {
    height: 46px !important;
   
}

.custom-control-label{
    cursor: pointer;
}
.form-control+div:before {
    color: #1a1a1a;
}

.form-control:focus+div:before {
    color: var(--text-color) !important;
    border-color: var(--text-color) !important;

}

.phoneErrorshow .form-control+div::before{
    color: red !important;
}

/* .phoneErrorshow .special-label{
    color: red !important;
} */


.phoneErrorshow .form-control:focus {
    border: 1px solid red !important ;
    box-shadow: none !important;
}

.phoneErrorshow .form-control {
    border: 1px solid red !important ;
    box-shadow: none !important;
}


.showemailError {
    color: red;
}



/* .react-tel-input .form-control:focus+div:before {
    color: var(--text-color) !important;
} */

/* .react-tel-input .form-control:hover+div:before {
    color: var(--text-color) !important;
} */

.country-name{
    color:  #1a1a1a !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;}