.bg {
    background-image: url('../../assets/img/grey_pattern.png');
    background-position: top;
    display: flex;
    padding-bottom: 40px;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}
.side-section-container {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    margin-right: 0px !important;
    
   
}
.video-image-container {
    min-width: 320px;
    height: 197px;
}
.sideBar {
    display: flex;
    flex-direction: row;
    padding: 0px 50px 0px 50px;
    justify-content: space-evenly;
    font-family: 'Roboto', sans-serif !important;
}
.donation-container {
    flex: 2.5;
    margin-right: 15px;
    font-family: 'Roboto', sans-serif !important;
}
.makeStyles-root-289 {
    padding: 0px 12px;
    box-sizing: border-box;
}

.sidebar_class{
    margin-top: 57px;
    flex: 1;
    margin-left: 15px;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgb(236, 234, 234);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-family: 'Roboto', sans-serif !important;
    max-width: 450px;
    position: relative;
    max-width: 350px;
}
.MuiExpansionPanel-root{
    box-shadow: 0px 1px 4px #fff !important;
    position : inherit !important;
}
.MuiExpansionPanel-rounded {
    border-top: 1px solid rgba(223, 220, 220, 0.931) !important ;
}
.MuiExpansionPanel-rounded:first-child {
    border-top: 0px !important;
     padding: 0px !important;
}
.MuiExpansionPanelSummary-root {
    padding: 0px !important;
}



.MuiExpansionPanelSummary-content {
    margin: 18px 0px 16px 0px !important;
}
.makeStyles-heading-348 {
    text-align: left !important;
    font: Regular 16px/28px Roboto !important;
    letter-spacing: 0px !important;
    color: #1A1A1A !important;
    opacity: 1 !important;
}
.makeStyles-paraghText-350{
    margin-top: -2px !important;
    
}
.MuiExpansionPanelDetails-root {
   padding: 0px !important;
   padding-bottom: 18px !important;
}
/* .MuiExpansionPanelDetails-root{
    border-bottom: 1px solid rgba(223, 220, 220, 0.931);
} */

.show {
    z-index: 99;
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
}

.hide {
    z-index: 0;
    height: 0px;
    width: 0px;
    transition: width 0.5s ease-out;
    transition: height 0.5s ease;
}

.donation-container {
    display: block;
    max-width: 750px;
    padding: 20px 0;
    justify-content: center;
    padding-top: 57px;
    padding-bottom: 0px;
}

.MuiInput-underline:before {
    border-bottom: 1px solid #f0f2f4 !important;
}

.MuiExpansionPanelDetails-root {
    box-shadow: none !important;
}



@media (max-width:960px) {
    .donation-container {
        max-width: 750px;
        padding: 10px 0;
        margin: 0 auto;
        padding-bottom: 0;
    }
    .side-section-container {
        margin-top: 10px !important;
    }
    .sideBar {
        display: block;
        padding: 20px;

    }
    .sidebar_class {
        margin :0px auto !important;
        max-width: 750px;
    }
    .img.side-bar-img{
        border-top-left-radius: 4px !important;
    }
    .donation-card {
        width: auto;
        margin: 0;
    }

    .donate_button {
        width : 100%;
        display: flex;
        margin-left: 0;
    }
    .donation_btn button {
        display: inline-block;
        margin: 1% !important;
        flex: 1;
    }

    .donation_btn button:first-child {
        margin-left: 0 !important;
    }
    .donation_btn button:last-child {
        margin-right: 0 !important;
    }


    .amountText {
        margin: 8px 0;
        width: 100%;
        margin-top: 18px;
    }

    .sideBar {
        margin-top: 30px;
    }
    .secured_text-top {
    
        margin-right: 18%;
    }
    .video-image-container {
        width: 100% !important;
        height: 230px;
        /* background-color: lawngreen; */
    }
    .secured_text-top {
        display: none;
    }
}

/*container*/

.side-section-container {
    margin-right: 20px;
    background-color: #fff;
}

.message-container {
    /* width: 100%; */
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #74747448;
    padding: 0px 30px !important;
}
.Accordian{
    padding: 0px 30px !important;
}

.MuiExpansionPanel-root.Mui-expanded {
    margin: 0px !important;
}

img.side-bar-img {
    width: 100%;
    height: inherit;
}

p.message-side-bar-text {
    
    text-align: left;
    font:  14px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #747474;
    line-height: 25px;
    margin: 0px;
    padding: 30px 0px;
}
.readMoreText {
    display: block;
    text-transform: uppercase;
    color: var(--text-color) !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.got-questions{
    margin: 0px !important;
    margin: 30px 0px 12px 0px !important;
    font-size: 24px;
    font-family: Roboto-Light;
    padding: 0px 30px;
    color: #1A1A1A !important;
}


@media (max-width:768px) {

    .bg {
        padding-top: 0;
        padding-bottom: 13px;
        display: block;
    }
    .sideBar {
      padding: 0px;
      margin-top: 0px;
    }
    .side-section-container {
        width: 100%;
        margin: 0px;
    }
    .donation-container {
        display: block;
        justify-content: center;
    }

    .donation-card {
        padding: 9px 15px;
    }

}

.secured_text-top {
    color: #A9A9A9;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 11px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: -40px;
    width: 100%;
    text-align: end;
    margin-left: 0%;
    position: absolute;
    top: -51px;
    right: 0px;
}

@media (max-width:586px) {
    .secured_text {
        margin-bottom: 30px;
    }
    
    .side-section-container {
        width: 100%;
        margin: 0px;
    }

}

@media (max-width:440px) {
    .side-section-container {
        width: 100%;
        margin: 0px;
    }
    
.secured_text-top {
    margin-left: -5px;
}

}

@media (max-width:340px) {
 
    .side-section-container {
        width: 100%;
        margin: 0px;
    }

    
.secured_text-top {
    margin-left: -5px;
    
}

}

@media (min-width:586px) {
    .secured_text {
        margin-bottom: 30px;
    }
    .side-section-container {
        width: 100%;
        margin: 0px;
    }
    


}

@media (min-width: 960px){
.secured_text-top {
    margin-left: -8%;
}
}