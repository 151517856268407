.makeStyles-paraghText-362 {
    margin: -4px 0 0 0 !important;
}

.MuiTypography-root {
    margin: -4px 0 0 0 !important;
}

.got-questions {
    margin-bottom: 16px !important;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('./assets/Fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./assets/Fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('./assets/Fonts/Roboto-Medium.ttf');
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('./assets/Fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/Fonts/Helvetica.ttf');
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./assets/Fonts/OpenSans-Regular.ttf');
}

.App {
    width: 100%;
    height: 100vh;
    background-image: url('./assets/img/Khushu-Pattern-Design_3.png');
}

.app-container-hide{
    display: none;
}

.app-container-show{
    display: block;
}

/* .react-tel-input .country-list {
    overflow-y: auto;
    overflow-x: auto;

} */

@media (max-width:960px) {
    .logo-on-lg{
        display: none !important;
    }
    
}

@media (min-width:960px) {
    .logo-on-sm{
        display: none !important;

    }
}
